<template>
  <div>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <!-- width should be 18, but this logo sucks -->

      <base-img
          :src="require('@/assets/logo-tdf.jpg')"
          contain
           height="80"
           max-width="200"
        />

      <v-spacer/>

      <v-btn
        v-if="items.length > 0"
        @click="drawer = !drawer"
        fab
        small
        dark
        color="primary"
      >
        <font-awesome-icon icon="bars" />
      </v-btn>

    </v-app-bar>

    <drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    Drawer: () => import("./Drawer")
  },

  data: () => ({
    drawer: null,
    tab: null
  }),

  props: {
    items: Array
  }
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
